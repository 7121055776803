import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import '../assets/style.css';
import NavTabs from './NavTabs';

function Client() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [soThe, setSoThe] = useState('');
  const [hoTen, setHoTen] = useState('');
  const [bophan, setBophan] = useState('');
  const [ngaydk, setNgaydk] = useState('');

  document.title = "Nhập dữ liệu";

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

      const headerRow = sheetData[10];
      const dataRows = sheetData.slice(11);

      const processedData = [];
      const seenEntries = new Set();

      dataRows.forEach((row) => {
        if (!row[1]) return;
        headerRow.slice(8).forEach((ngay, index) => {
          if (ngay) {
            const formattedDate = parseDateString(ngay);
            if (formattedDate) {
              const soThe = row[1].toString().padStart(5, '0');
              const entryKey = `${soThe}-${formattedDate.toDateString()}`;
              if (!seenEntries.has(entryKey)) {
                seenEntries.add(entryKey);
                processedData.push({
                  soThe,
                  hoTen: `${row[2]} ${row[3]}`,
                  bophan: `${row[4]}`,
                  ngaydk: formattedDate,
                  trangthai: 'Chưa ăn',
                  importTime: new Date(),
                });
              }
            }
          }
        });
      });

      setData(processedData);
    };
    reader.readAsBinaryString(file);
  };

  const parseDateString = (dateStr) => {
    const match = dateStr.match(/Ngày (\d{2}) tháng (\d{2})/);
    if (match) {
      const day = match[1];
      const month = match[2];
      const year = new Date().getFullYear();
      return new Date(`${year}-${month}-${day}`);
    }
    return null;
  };

  const formatDateToVietnamese = (date) => {
    return new Intl.DateTimeFormat('vi-VN', {
      day: 'numeric',
      month: '2-digit',
      year: 'numeric',
    }).format(date);
  };

  const handleFileSubmit = async () => {
    if (data.length === 0) {
      setError('Không có dữ liệu hợp lệ để tải lên.');
      return;
    }

    setError(null);
    

    try {
      await axios.post('https://serversa.vietlonghung.com.vn/upload-csv', data, {
        headers: {
          'Content-Type': 'application/json',
          
        },
      
      });
      alert('Tải lên file thành công!');
      setData([]); // Đặt lại trạng thái dữ liệu để quay về chỗ import
      setSelectedFile(null);
    } catch (error) {
      alert('Tải lên file thất bại.');
      console.error(error);
    }
  };

  const handleManualSubmit = async (event) => {
    event.preventDefault();
    const formData = { soThe, hoTen, bophan, ngaydk: new Date(ngaydk) };

    try {
      const response = await axios.post('https://serversa.vietlonghung.com.vn/check-duplicate', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.isDuplicate) {
        setError('Số thẻ đã tồn tại trong ngày đã chọn.');
        alert('Số thẻ đã tồn tại trong ngày!');
        return;
      }

      await axios.post('https://serversa.vietlonghung.com.vn/upload-manual', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      alert('Thêm dữ liệu thành công!');
      setSoThe('');
      setHoTen('');
      setBophan('');
      setNgaydk('');
      setError('');
    } catch (error) {
      alert('Lỗi khi thêm dữ liệu.');
      console.error(error);
    }
  };

  return (
    <div>
      <NavTabs/>
      <div className="container bg-web mt-3">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button className="nav-link active" id="nav-input-csv-tab" data-bs-toggle="tab" data-bs-target="#nav-input-csv" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
              Nhập dữ liệu từ file CSV
            </button>
            <button className="nav-link" id="nav-input-manual-tab" data-bs-toggle="tab" data-bs-target="#nav-input-manual" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
              Nhập dữ liệu thủ công
            </button>
          </div>
        </nav>
        <div className="tab-content py-4" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-input-csv" role="tabpanel" aria-labelledby="nav-input-csv-tab">
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">Chọn file csv để nhập dữ liệu</label>
              <input className="form-control" type="file" id="formFile" accept=".csv" onChange={handleFileUpload} />
            </div>
            {error && <div className="error">{error}</div>}
            {data.length > 0 && (
              <div>
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>Số thẻ</th>
                      <th>Họ tên</th>
                      <th>Bộ phận</th>
                      <th>Ngày đăng ký</th>
                      <th>Trạng thái</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td>{row.soThe}</td>
                        <td>{row.hoTen}</td>
                        <td>{row.bophan}</td>
                        <td>{formatDateToVietnamese(row.ngaydk)}</td>
                        <td>{row.trangthai}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button className="btn btn-primary" onClick={handleFileSubmit}>
                  Tải lên
                </button>
              </div>
            )}
          </div>
          <div className="tab-pane fade" id="nav-input-manual" role="tabpanel" aria-labelledby="nav-input-manual-tab">
            <form onSubmit={handleManualSubmit} className="manual-entry-form">
              <div className="mb-4 row">
                <label htmlFor="idnv" className="col-sm-2 col-form-label">Số thẻ NV</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" pattern="[0-9]{5}" id="idnv" placeholder="Nhập số thẻ nhân viên gồm 5 số vd:09012"
                    title="Nhập số thẻ nhân viên gồm 5 số"
                    value={soThe} onChange={(e) => setSoThe(e.target.value)} required />
                  <div className="invalid-feedback">Nhập số thẻ nhân viên gồm 5 số</div>
                </div>
              </div>
              <div className="mb-4 row">
                <label htmlFor="name-nv" className="col-sm-2 col-form-label">Họ tên NV</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" id="name-nv" placeholder="Nhập đầy đủ họ tên nhân viên vd:Nguyễn Văn B"
                    value={hoTen} onChange={(e) => setHoTen(e.target.value)} required />
                  <div className="invalid-feedback">Nhập đầy đủ họ tên nhân viên</div>
                </div>
              </div>
              <div className="mb-4 row">
                <label htmlFor="bo-phan" className="col-sm-2 col-form-label">Bộ phận</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" id="bo-phan" placeholder="Xưởng ...."
                    value={bophan} onChange={(e) => setBophan(e.target.value)} required />
                  <div className="invalid-feedback">Nhập đầy đủ bộ phận nhân viên</div>
                </div>
              </div>
              <div className="mb-4 row">
                <label htmlFor="date-regis" className="col-sm-2 col-form-label">Ngày đăng ký suất ăn</label>
                <div className="col-sm-10">
                  <input type="date" className="form-control" id="date-regis" value={ngaydk} onChange={(e) => setNgaydk(e.target.value)} required />
                  <div className="invalid-feedback">Nhập ngày đăng ký suất ăn</div>
                </div>
              </div>
              <div className="row">
                <button type="submit" className="col-md-6 col-lg-4 col-12 btn btn-success height-fill-available mb-4 font-size-1-2 mx-auto">Thêm suất ăn</button>
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Client;
