  import React, { useState, useEffect,useMemo } from 'react';
  import axios from 'axios';
  import moment from 'moment';
  import { useNavigate, useLocation } from 'react-router-dom';
  import DateRangePicker from 'react-bootstrap-daterangepicker';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import 'bootstrap-daterangepicker/daterangepicker.css';
  import NavTabs from './NavTabs';
  import './OvertimeApproval.css';
  import ApprovalFlowNavTabs from './Approvalworkflow';
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { messaging } from './firebase';
  import { getToken } from "firebase/messaging";
  import { onMessage } from "firebase/messaging";
  import { API_URL } from '../authlogin/api';
  
  function OvertimeApproval() {
    const [dateRange, setDateRange] = useState({
      startDate: moment().format('DD/MM/YYYY'),
      endDate: moment().format('DD/MM/YYYY')
    });
    const getCurrentTime = () => {  
      const now = new Date();  
      const hours = String(now.getHours()).padStart(2, '0'); // Đảm bảo có 2 chữ số  
      const minutes = String(now.getMinutes()).padStart(2, '0'); // Đảm bảo có 2 chữ số  
      return `${hours}:${minutes}`;  
    };  
    // Khởi tạo state với giờ hiện tại  
    const [timeRange, setTimeRange] = useState({  
      startTime: '16:30',  
      endTime: '20:00' 
    });
    const [employees, setEmployees] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();
    const [selectedBoPhan, setSelectedBoPhan] = useState('');
    const [selectedPhongBan, setSelectedPhongBan] = useState('');
    const [selectedEmployees, setSelectedEmployees] = useState({});
    const [currentCapDo, setCurrentCapDo] = useState('');
    const [availableCapDo, setAvailableCapDo] = useState([]);
    const [boPhanList, setBoPhanList] = useState([]);
    const [phongBanList, setPhongBanList] = useState([]);
    const [totalEmployees, setTotalEmployees] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [showApprovalFlow, setshowApprovalFlow] = useState(false);
    const [selectedEmployeeCount, setSelectedEmployeeCount] = useState(0);
    const [departmentSummary, setDepartmentSummary] = useState({});
    const [approvedCounts, setApprovedCounts] = useState({});
    const [canEnterReason, setCanEnterReason] = useState(false);
    const [overtimeReasons, setOvertimeReasons] = useState('');
    const [approvalData, setApprovalData] = useState({});
    const [overtimeHours, setOvertimeHours] = useState('1:00');
    const [Customers, setCustomers] = useState('');
    const [Productcode, setProductcode] = useState('');
    const [ProposedName, setProposedName] = useState('');
    const [nhomngaydangkybatdau, setNhomngaydangkybatdau] = useState('');
    const [nhomngaydangkyketthuc, setNhomngaydangkyketthuc] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadings, setIsLoadings] = useState(false);
    const [overtimeError, setOvertimeError] = useState('');
    const [hasUserSelectedTime, setHasUserSelectedTime] = useState(false);
    const [isShowData, setIsShowData] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState('');

    useEffect(() => {
      document.title = "Duyệt suất ăn";
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
      } else {
        fetchUserInfo();
        
      }
    }, [navigate]);
    

  
    useEffect(() => {
      if (userInfo && dateRange.startDate && dateRange.endDate) {
        fetchEmployees();
        fetchApprovalFlow();
        checkReasonPermission();
        fetchNotifications();
      }
    }, [dateRange, selectedBoPhan, selectedPhongBan, currentCapDo, userInfo]);

    useEffect(() => {
      const summary = createDepartmentSummary(employees);
      setDepartmentSummary(summary);
    }, [employees, selectedEmployees, dateRange]);

   useEffect(() => {
      const summary = createDepartmentSummary(employees);
      setDepartmentSummary(summary);
    }, [employees, selectedEmployees, dateRange]);

    useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const boPhanParam = searchParams.get('boPhan');
    const phongBanParam = searchParams.get('phongBan');
    const startDateParam = searchParams.get('startDate');
    const endDateParam = searchParams.get('endDate');
    const showApprovalFlowParam = searchParams.get('showApprovalFlow');
    if (showApprovalFlowParam === 'true') {
      setshowApprovalFlow(true);  
    }  
    if (boPhanParam) setSelectedBoPhan(boPhanParam);
    if (phongBanParam) {
      setSelectedPhongBan(phongBanParam);
      setSelectedDepartment(phongBanParam);
    }
    if (startDateParam && endDateParam) {
      setDateRange({
        startDate: startDateParam,
        endDate: endDateParam
      });
    }
    }, [location]);

  useEffect(() => {
    requestNotificationPermission();
  }, []);
 useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      toast.info(payload.notification.body);
    });

    return () => {
      unsubscribe();
    };
 }, []);
    
const getPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return 'android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }
  return 'web';
    };
    
const vapidKey = 'BF8gF-tiOKXY6oAKz8qjCpjCudtnPnz75ZQSbUbcPQxUc2Y8flAklj1OE1SIir5hIS3fuhYpxkG7eu8cBWpth6I';

const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, { vapidKey });
      // console.log("Token:", token);
      await sendTokenToServer(token);
    } else {
      console.log('Notification permission denied');
    }
  } catch (error) {
    console.error('Error requesting notification permission:', error);
  }
}; 
  
  const sendTokenToServer = async (token) => {
    try {
    const platform = getPlatform();
    const response = await axios.post(`${API_URL}/register-device`, { token,platform }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  } catch (error) {
    console.error('Error sending token to server:', error);
  }
};

  const showNotification = (message) => {
    toast(message);
  };
     
     // Hàm để lấy thông tin luồng duyệt
     
    const fetchApprovalFlow = async () => {
  try {
    const response = await axios.get(`${API_URL}/approval-flow`, {
      params: {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        department: selectedDepartment
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    setApprovalData(response.data);
  } catch (error) {
    console.error('Error fetching approval flow:', error);
  }
  };
    const checkReasonPermission = async () => {
  try {
    const response = await axios.get(`${API_URL}/check-reason-permission`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    setCanEnterReason(response.data.canEnterReason);
  } catch (error) {
    console.error('Error checking reason permission:', error);
  }
};
  
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(`${API_URL}/user-info`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setUserInfo(response.data);
        setCurrentCapDo(response.data.capDo);
        setAvailableCapDo(response.data.availableCapDo);
        setIsShowData(response.data.isShowData);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    const fetchEmployees = async () => {
      try {
        const params = {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          boPhan: selectedBoPhan,
          phongBan: selectedPhongBan,
          capDo: currentCapDo,
        };  
        const response = await axios.get(`${API_URL}/overtime`, {
          params,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setEmployees(response.data);
        setTotalEmployees(response.data.length);
        const newSelectedEmployees = {};
        response.data.forEach(employee => {
          newSelectedEmployees[employee.id] = true;
        });
        setSelectedEmployees(newSelectedEmployees);
        setSelectedEmployeeCount(response.data.length);
        
        const uniqueBoPhan = [...new Set(response.data.map(emp => emp.BoPhan))];
        const uniquePhongBan = [...new Set(response.data.map(emp => emp.PhongBan))];

        // Cập nhật state cho danh sách bộ phận và phòng ban
        setBoPhanList(uniqueBoPhan.map(name => ({ id: name, name: name })));
        setPhongBanList(uniquePhongBan.map(name => ({ id: name, name: name })));
        
      } catch (error) {
        console.error('Error fetching overtime data:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    };

    const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    const newSelectedEmployees = {};
    employees.forEach(employee => {
      newSelectedEmployees[employee.id] = isChecked;
    });
    setSelectedEmployees(newSelectedEmployees);
    setSelectedEmployeeCount(isChecked ? employees.length : 0);
  };

  const handleSelectEmployee = (employeeId) => {
    setSelectedEmployees(prev => {
      const newSelection = { ...prev, [employeeId]: !prev[employeeId] };
      setSelectedEmployeeCount(Object.values(newSelection).filter(Boolean).length);
      return newSelection;
    });
  };

    const handleDateChange = (event, picker) => {
    const newDateRange = {
      startDate: picker.startDate.format('DD/MM/YYYY'),
      endDate: picker.endDate.format('DD/MM/YYYY')
      };
      setNhomngaydangkybatdau(picker.startDate.format('YYYY-MM-DD'));
      setNhomngaydangkyketthuc(picker.endDate.format('YYYY-MM-DD'));
      setDateRange(newDateRange);
      updateURL(newDateRange);
  };
    const handleBoPhanChange = (e) => {
    const value = e.target.value;
    setSelectedBoPhan(value);
    updateURL({ boPhan: value });

  };

  const handlePhongBanChange = (e) => {
    const value = e.target.value;
    setSelectedPhongBan(value);
    setSelectedDepartment(value);
    updateURL({ phongBan: value });

  };

    const updateURL = (params) => {
    const searchParams = new URLSearchParams(window.location.search);
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };
    
    const goToDataEntry = () => {
    window.open('/nhapdulieu', '_blank');
  };
    const handleOvertimeHoursChange = (e) => {
      setOvertimeHours(e.target.value);
      setHasUserSelectedTime(true);
      setOvertimeError('');
  };
  const validateOvertimeHours = () => {
    if (currentCapDo === '2' && !!hasUserSelectedTime) {
      setOvertimeError('Vui lòng chọn giờ làm thêm');
      return false;
    }
    return true;
  };
    const handleApprove = async (approveType) => {
    if (isLoading) return; // Ngăn chặn việc bấm nhiều lần
    if (canEnterReason && !overtimeReasons.trim() ) {
      alert('Vui lòng nhập lý do ăn ca trước khi duyệt.');
      return;
      }
      if (!validateOvertimeHours()) {
      return;
    }
    if (canEnterReason && !ProposedName.trim() ) {
      alert('Vui lòng nhập tên đề xuất trước khi duyệt.');
      return;
    }
    setIsLoading(true); // Bắt đầu loading
    try {
      let employeesToApprove;
      if (approveType === 'all' && currentCapDo >= '4') {
        // Nếu duyệt tất cả và có quyền, vẫn lọc theo bộ phận đã chọn
        employeesToApprove = employees
          .filter(emp => (!selectedBoPhan || emp.BoPhan === selectedBoPhan) &&
                          (!selectedPhongBan || emp.PhongBan === selectedPhongBan))
          .map(emp => emp.id);
      } else {
        // Nếu duyệt theo lựa chọn, chỉ duyệt những nhân viên được chọn và thuộc bộ phận đã lọc
        employeesToApprove = Object.keys(selectedEmployees)
          .filter(id => selectedEmployees[id])
          .filter(id => {
            const emp = employees.find(e => e.id === id);
            return (!selectedBoPhan || emp.BoPhan === selectedBoPhan) &&
                    (!selectedPhongBan || emp.PhongBan === selectedPhongBan);
          });
      }

      if (employeesToApprove.length === 0) {
        alert('Vui lòng chọn ít nhất một nhân viên để duyệt');
        return;
      }

        const response = await axios.post(`${API_URL}/approve-selected-overtime`, {
          employeeIds: employeesToApprove,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          capDo: currentCapDo,
          overtimeReasons,
          overtimeHours,
          Productcode,
          Customers,
          nhomngaydangkybatdau,
          nhomngaydangkyketthuc,
          approveType: approveType,
          ProposedName,
          
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        showNotification('Đã duyệt thành công!');
        const dates = getDatesArray(dateRange.startDate, dateRange.endDate);
        
        setApprovedCounts(prevCounts => {
          const newCounts = JSON.parse(JSON.stringify(prevCounts)); // Tạo bản sao sâu
          employeesToApprove.forEach(id => {
            const employee = employees.find(emp => emp.id === id);
            if (employee) {
              if (!newCounts[employee.PhongBan]) {
                newCounts[employee.PhongBan] = {};
              }
              dates.forEach(date => {
                if (employee.overtime?.[date]?.tangCa) {
                  if (!newCounts[employee.PhongBan][date]) {
                    newCounts[employee.PhongBan][date] = 0;
                  }
                  newCounts[employee.PhongBan][date] += 1; // Chỉ tăng 1 cho mỗi nhân viên và ngày
                }
              });
            }
          });
          
          return newCounts;
        });

        // Cập nhật danh sách nhân viên và trạng thái lựa chọn
        setEmployees(prevEmployees => {
          const updatedEmployees = prevEmployees.filter(emp => !employeesToApprove.includes(emp.id));
          
          return updatedEmployees;
        });
        
        setSelectedEmployeeCount(prevCount => {
          const newCount = prevCount - employeesToApprove.length;
          
          return newCount;
        });
        
        setSelectedEmployees(prevSelected => {
          const newSelected = { ...prevSelected };
          employeesToApprove.forEach(id => {
            delete newSelected[id];
          });
          
          return newSelected;
        });
        // alert(response.data.message || 'Đã duyệt các mục đã chọn');
        // Cập nhật lại danh sách nhân viên và luồng duyệt
      } catch (error) {
        console.error('Error approving overtime:', error);
        alert(error.response?.data?.message || 'Có lỗi xảy ra khi duyệt');
      }finally {
          setIsLoading(false); // Kết thúc loading bất kể thành công hay thất bại
        }
      };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${API_URL}/notifications`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);  
    }
  };

  const ExcelJS = require('exceljs');
  
  const exportToExcel = async () => {
    try {
      const response = await axios.get(`${API_URL}/export-overtime`, {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `OvertimeReport_${dateRange.startDate}-${dateRange.endDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      alert('Có lỗi xảy ra khi xuất file Excel');
    }
  };
  
  const exportToExcelByDepartment = async () => {
    try {
      // Lấy ngày bắt đầu và kết thúc từ state dateRange
      //const { startDate, endDate } = dateRange;
      if (isLoadings) return; // Ngăn chặn việc bấm nhiều lần
      setIsLoadings(true); // Bắt đầu loading
      const startDate= dateRange.startDate
      const endDate = dateRange.endDate
      // Tạo URL cho request với query params
      const url = `${API_URL}/export-by-department?startDate=${startDate}&endDate=${endDate}&overtimeHours=${overtimeHours}`;
  
      // Gọi API sử dụng fetch
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Lấy blob từ response
      const blob = await response.blob();
  
      // Tạo URL object từ blob
      const downloadUrl = window.URL.createObjectURL(blob);
  
      // Tạo một thẻ a tạm thời để tải file
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `Bieu_mau_danh_sach_Tang_Ca_ngay_${startDate}-${endDate}.xlsx`;
  
      // Thêm link vào body
      document.body.appendChild(link);
  
      // Simulate click để tải file
      link.click();
  
      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
  
    } catch (error) {
      console.error('Error exporting Excel:', error);
      alert('Có lỗi xảy ra khi xuất file Excel');
    }finally {
      setIsLoadings(false); // Kết thúc loading bất kể thành công hay thất bại
    }
  };

 
  // Hàm hỗ trợ để thêm ngày

    const getDatesArray = (start, end) => {
      const dates = [];
      let currentDate = moment(start, 'DD/MM/YYYY');
      const endDate = moment(end, 'DD/MM/YYYY');
      while (currentDate <= endDate) {
        dates.push(currentDate.format('DD/MM/YYYY'));
        currentDate.add(1, 'days');
      }
      return dates;
    };
    
    
   const createDepartmentSummary = (employees) => {
    const summary = {};
    const dates = getDatesArray(dateRange.startDate, dateRange.endDate);

    employees.forEach(employee => {
      if (!summary[employee.PhongBan]) {
        summary[employee.PhongBan] = {};
        dates.forEach(date => {
          summary[employee.PhongBan][date] = { registered: 0, approved: 0, checked: 0 };
        });
      }
      dates.forEach(date => {
        if (employee.overtime?.[date]?.tangCa) {
          summary[employee.PhongBan][date].registered++;
        }
        if (selectedEmployees[employee.id]) {
          summary[employee.PhongBan][date].checked++;
        }
      });
    });

    // Thêm số lượng đã duyệt từ approvedCounts
    Object.entries(approvedCounts).forEach(([phongBan, dateCounts]) => {
      if (summary[phongBan]) {
        Object.entries(dateCounts).forEach(([date, count]) => {
          if (summary[phongBan][date]) {
            summary[phongBan][date].approved = count;
          }
        });
      }
    });

    return summary;
    };
    const handleHeaderClick = (field) => {
    setSearchField(field);
    setSearchTerm('');
  };
    
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    };
    // hàm tìm kiếm
  const filteredEmployees = useMemo(() => {
  return employees.filter(employee => {
    // Lọc theo bộ phận và phòng ban
    if (selectedBoPhan && employee.BoPhan !== selectedBoPhan) return false;
    if (selectedPhongBan && employee.PhongBan !== selectedPhongBan) return false;
    
    // Lọc theo từ khóa tìm kiếm
    if (searchTerm) {
      const normalizedSearchTerm = searchTerm.toLowerCase().trim();
      if (searchField === 'MaNV') {
        return employee.MaNV.toLowerCase().includes(normalizedSearchTerm);
      } else if (searchField === 'TenNV') {
        return employee.TenNV.toLowerCase().includes(normalizedSearchTerm);
      }
    }
    
    return true;
  });
}, [employees, selectedBoPhan, selectedPhongBan, searchTerm, searchField]);

  // Hàm render để hiển thị luồng duyệt
  const renderApprovalFlow = () => {
    if (!showApprovalFlow) return null;
    return (
      <ApprovalFlowNavTabs
        dateRange={dateRange}
        approvalData={approvalData}
        deptId={selectedDepartment}
        availableDepartments={phongBanList.map(pb => pb.name)}
      />
    );
  };
    const renderEmployeeTable = () => {
    const dates = getDatesArray(dateRange.startDate, dateRange.endDate);
    if (!isShowData) {
      return null;
    }
    // const filteredEmployees = employees.filter(employee => {
    //   if (selectedBoPhan && employee.BoPhan !== selectedBoPhan) return false;
    //   if (selectedPhongBan && employee.PhongBan !== selectedPhongBan) return false;
    //   return true;
    // });
    // Tính toán số lượng người được chọn cho mỗi ngày
    const selectedCounts = dates.reduce((acc, date) => {
      acc[date] = filteredEmployees.filter(emp => 
        selectedEmployees[emp.id] && emp.overtime?.[date]?.tangCa
      ).length;
      return acc;
    }, {});
      return (
        <>
       
          
          <div className='employee-table'>
          {searchField && (
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder={`Tìm kiếm theo ${searchField === 'MaNV' ? 'Mã NV' : 'Họ Tên'}`}
                value={searchTerm}
                onChange={handleSearch} 
              />
            </div>
          )}  
        <table className="table table-bordered">
          <thead>
            <tr className='fs-5'>
              <th rowSpan="2">
                <input type="checkbox" onChange={handleSelectAll} />
              </th>  
              <th rowSpan="2">STT</th>
              <th rowSpan="2" onClick={() => handleHeaderClick('MaNV')} style={{cursor: 'pointer'}}>Mã NV 🔎</th>
              <th rowSpan="2" onClick={() => handleHeaderClick('TenNV')} style={{cursor: 'pointer'}}>Họ Tên 🔎</th>
              {currentCapDo >= '3' && <th rowSpan="2">Phòng ban</th>}
              <th rowSpan="2">Bộ phận</th>
              {dates.map(date => (  
                <th key={date} colSpan="1">{date}</th>
              ))}
            </tr>
            <tr>
              {dates.map(date => ( 
                <th key={date}>
                  ăn ca
                  <br />
                  <small>(đã chọn: {selectedCounts[date]})</small>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredEmployees.map((employee, index) => (
              <tr  key={employee.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedEmployees[employee.id] || false}
                    onChange={() => handleSelectEmployee(employee.id)}
                  />
                </td>
                <td>{index + 1}</td>
                <td>{employee.MaNV}</td>
                <td>{employee.TenNV}</td>
                {currentCapDo >= '3' && <td>{employee.PhongBan}</td>}
                <td>{employee.BoPhan}</td>
                {dates.map(date => (
                  <React.Fragment key={date}>
                    {/* <td>{employee.overtime?.[date]?.trua ? <strong>'✓'</strong> : ''}</td> */}
                    <td className='fs-5'>{employee.overtime?.[date]?.tangCa ? <strong>✓</strong>: ''}</td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
         
          </div>
          </>
      );
    };
    return (<>
    <NavTabs/>
      <div className="overtime-approval">
        
        <h2>Duyệt danh sách ăn ca</h2>
        
        <div className="filter-controls">
          <div className="row action-buttons">
            <div className="col-xl col-md-6 ">
              <label>Chọn khoảng thời gian:</label>
              <DateRangePicker
              initialSettings={{
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                maxDate: moment('DD/MM/YYYY'),
                locale: {
                  format: 'DD/MM/YYYY'
                }
              }}
              onApply={handleDateChange}
            >
              <input 
                type="text" 
                className="form-control" 
                value={`${dateRange.startDate} - ${dateRange.endDate}`} 
                readOnly 
              />
            </DateRangePicker>
            </div>
            
            
             

            {currentCapDo >= '2' && (
            <div className="col-xl col-md-6 ">
              <label>Chọn phòng ban:</label>
              <select 
                className="form-control" 
                value={selectedPhongBan} 
                onChange={handlePhongBanChange}
              >
                <option value="">Tất cả</option>
                {phongBanList.map((phongBan) => (
                  <option key={phongBan.id} value={phongBan.name}>{phongBan.name}</option>
                ))}
              </select>
            </div>
          )}
          {currentCapDo >= '2' && (
            <div className="col-xl col-md-6 ">
              <label>Chọn bộ phận:</label>
              <select 
                className="form-control" 
                value={selectedBoPhan} 
                onChange={handleBoPhanChange}
              >
                <option value="">Tất cả</option>
                {boPhanList.map((boPhan) => (
                  <option key={boPhan.id} value={boPhan.name}>{boPhan.name}</option>
                ))}
              </select>
            </div>
          )}
              
              </div>
          
          <div className="row mt-3 action-buttons">
            {userInfo && (
              <div className="col-md ">
                <button 
                className="btn btn-primary w-100" 
                onClick={() => handleApprove('selected')}
                disabled={isLoading||(currentCapDo === 2 && !hasUserSelectedTime)}
              >
                {isLoading ? (
                  <>
                    <span className="spinner"></span>
                    Đang duyệt...
                  </>
                ) : 'Duyệt DS đã chọn'}
              </button>
              </div>
            )}
            
            {currentCapDo == '2' && (
             <div className="col-md ">
             <button 
               className="btn btn-success w-100" 
               onClick={exportToExcelByDepartment}
               disabled={isLoadings}
             >
               {isLoadings ? (
                 <>
                   <span className="spinner"></span>
                   Đang xuất...
                 </>
               ) : 'Xuất excel ký tên'}
             </button>
           </div>
            )}
            
            {currentCapDo >= '2' && (
            
              <div className="col-md">
                <button 
                  className="btn btn-info w-100" 
                  onClick={() => setshowApprovalFlow(!showApprovalFlow)}
                >
                  {showApprovalFlow ? 'Ẩn' : 'Hiện'} Luồng Duyệt
                </button>
              </div>
          
          )}

            <div className="col-md">
              <button className="btn btn-success w-100" onClick={exportToExcel}>
                Xuất excel đã duyệt
              </button>
            </div>
          </div>
       
          {currentCapDo == '1' && (
            <div className="row mt-3">
              <div className="col-12">
                <button className="btn btn-primary w-100" onClick={goToDataEntry}>
                  Đến trang nhập dữ liệu
                </button>
              </div>
            </div>
          )}
        </div>
        <div className='reason  '>
          {canEnterReason && (
            <>
              <div className="row mt-3 action-buttons">
                <div className='col-xl col-md-6'>
                <label className="labe-b">Lý do ăn ca:</label>
                <input 
                  type="text"
                  className="form-control ld form-control-lg" 
                  value={overtimeReasons}
                  onChange={(e) => setOvertimeReasons(e.target.value)}
                  placeholder="Nhập lý do ăn ca*" 
                />
                  </div>
                <div className='col-xl col-md-6 '>
              <label className="labe-b">Tên đề xuất:</label>
              <input 
                type="text"
                className="form-control ld form-control-lg" 
                value={ProposedName}
                onChange={(e) => setProposedName(e.target.value)}
                placeholder="Nhập tên đề xuất"
              />
                </div>
                <div className='col-xl col-md-6 '>
              <label className="labe-b">Mã hàng:</label>
              <input 
                type="text"
                className="form-control form-control-lg" 
                value={Productcode}
                onChange={(e) => setProductcode(e.target.value)}
                placeholder="Nhập mã hàng"
              />
                </div>
                <div className='col-xl col-md-6 '>
              <label className="labe-b">Khách hàng:</label>
              <input 
                type="text"
                className="form-control form-control-lg" 
                value={Customers}
                onChange={(e) => setCustomers(e.target.value)}
                placeholder="Nhập khách hàng"
              />
                </div>
              {userInfo &&currentCapDo == '2'&& (
                <div className="col-xl col-md-6">
                  <label className="labe-b">Chọn giờ làm thêm:</label>
                  
                      <select className={`form-control form-control-lg ${overtimeError ? 'is-invalid' : ''}`}
                        value={overtimeHours}
                        onChange={handleOvertimeHoursChange}
                        required
                      >   
                        <option value="0:30">30 phút</option>
                        <option value="0:45">45 phút</option>
                        <option value="1:00">1:00</option>
                        <option value="1:15">1:15</option>
                        <option value="1:30">1:30</option>
                        <option value="1:45">1:45</option>
                        <option value="2:00">2:00</option> 
                        <option value="2:15">2:15</option> 
                        <option value="2:30">2:30</option> 
                        <option value="2:45">2:45</option> 
                        <option value="3:00">3:00</option>
                        <option value="3:15">3:15</option>
                        <option value="3:30">3:30</option>
                        <option value="3:45">3:45</option>
                        <option value="4:00">4:00</option>
                    </select>
                    {overtimeError && <div className="invalid-feedback">{overtimeError}</div>}
                    </div>
                  
            )}  
              </div>
            
            </>
          )}
          
        </div>
        
        <div className="table mt-3">     
          {renderApprovalFlow()}
          <div className='mb-3 '></div>
          {/* {currentCapDo < '3' && (
            <strong>Đã chọn: {selectedEmployeeCount} / {totalEmployees}</strong>
          )} */}
          
          {renderEmployeeTable()}
        </div>
      <ToastContainer />
      </div>
      </>
    );
  }

  export default OvertimeApproval;