import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChartComponent = ({ statusCounts }) => {
  // Tính tổng số lượng đã ăn và chưa ăn cho tất cả bộ phận
  const totalDaAn = Object.keys(statusCounts)
    .filter((key) => key.includes('Đã ăn'))
    .reduce((acc, key) => acc + statusCounts[key], 0);

  const totalChuaAn = Object.keys(statusCounts)
    .filter((key) => key.includes('Chưa ăn'))
    .reduce((acc, key) => acc + statusCounts[key], 0);

  // Tính tổng số lượng tất cả
  const totalCount = totalDaAn + totalChuaAn;

  // Tính phần trăm đã ăn và chưa ăn
  const percentDaAn = (totalDaAn / totalCount) * 100;
  const percentChuaAn = (totalChuaAn / totalCount) * 100;

  const data = {
    labels: ['Đã ăn', 'Chưa ăn'],
    datasets: [
      {
        data: [percentDaAn.toFixed(2), percentChuaAn.toFixed(2)],
        backgroundColor: ['#007bff', '#28a745'],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      datalabels: {
        color: '#fff',
        font: {
          weight: 'bold',
          size: 16,
        },
        formatter: (value) => {
          return value + '%';
        },
      },
    },
  };

  return (
    <div className="pie-chart">
      <h2>Biểu Đồ Trạng Thái</h2>
      <div style={{ height: '300px', width: '100%' }}>
        <Pie data={data} options={options} />
      </div>
    </div>
  );
};

export default PieChartComponent;