  import React, { useState, useEffect } from 'react';
  import axios from 'axios';
  import moment from 'moment';
  import { useNavigate } from 'react-router-dom';
  import DateRangePicker from 'react-bootstrap-daterangepicker';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import 'bootstrap-daterangepicker/daterangepicker.css';
  import NavTabs from './NavTabs';
  import './OvertimeApproval.css';
  import ApprovalFlow from './workflow';
  import { API_URL } from '../authlogin/api';
  
  
function Workflowview() {
    const [dateRange, setDateRange] = useState({
      startDate: moment().format('DD/MM/YYYY'),
      endDate: moment().format('DD/MM/YYYY')
    });  
    // Khởi tạo state với giờ hiện tại  
    const [timeRange, setTimeRange] = useState({  
      startTime: '16:30',  
      endTime: '20:00' 
    });
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();
    const [currentCapDo, setCurrentCapDo] = useState('');
    const [approvalData, setApprovalData] = useState({});
    const [isLoadings, setIsLoadings] = useState(false);

    useEffect(() => {
      document.title = "Xem luồng duyệt";
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
      } else {
        fetchUserInfo(); 
        
      }
    }, [navigate]);
  
    useEffect(() => {
      if (userInfo && dateRange.startDate && dateRange.endDate) {
        
        fetchApprovalFlow();
        
        
      }
    }, [dateRange, currentCapDo, userInfo]);

    

    
     // Hàm để lấy thông tin luồng duyệt
     
     const fetchApprovalFlow = async () => {
    try {
      const response = await axios.get(`${API_URL}/approval-flow`, {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setApprovalData(response.data);
    } catch (error) {
      console.error('Error fetching approval flow:', error);
    }
  };
  
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(`${API_URL}/user-info`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setUserInfo(response.data);
        setCurrentCapDo(response.data.capDo);
      } catch (error) { 
        console.error('Error fetching user info:', error);
      }
    };
    const handleDateChange = (event, picker) => {
      setDateRange({
        startDate: picker.startDate.format('DD/MM/YYYY'),
        endDate: picker.endDate.format('DD/MM/YYYY')
      });     
      
    };
   const handleExportCSV = async () => {
    try {
      const response = await axios.get(`${API_URL}/export-csv`, {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate  
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        responseType: 'blob' // Important: This tells axios to treat the response as binary data
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `overtime_${dateRange.startDate}_to_${dateRange.endDate}.csv`;
      link.click();

      // Clean up
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
  };
  const exportToExcelByDepartment = async () => {
    try {
      // Lấy ngày bắt đầu và kết thúc từ state dateRange
      //const { startDate, endDate } = dateRange;
      if (isLoadings) return; // Ngăn chặn việc bấm nhiều lần
      setIsLoadings(true); // Bắt đầu loading
      const startDate= dateRange.startDate
      const endDate = dateRange.endDate
      // Tạo URL cho request với query params
      const url = `${API_URL}/export-excel-explanation?startDate=${startDate}&endDate=${endDate}`;
  
      // Gọi API sử dụng fetch
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Lấy blob từ response
      const blob = await response.blob();
  
      // Tạo URL object từ blob
      const downloadUrl = window.URL.createObjectURL(blob);
  
      // Tạo một thẻ a tạm thời để tải file
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `Bieu_mau_danh_sach_Tang_Ca_ngay_${startDate}-${endDate}.xlsx`;
  
      // Thêm link vào body
      document.body.appendChild(link);
  
      // Simulate click để tải file
      link.click();
  
      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
  
    } catch (error) {
      console.error('Error exporting Excel:', error);
      alert('Có lỗi xảy ra khi xuất file Excel');
    }finally {
      setIsLoadings(false); // Kết thúc loading bất kể thành công hay thất bại
    }
  };
  const exportToExcel = async () => {
    try {
      const response = await axios.get(`${API_URL}/export-overtimeHCQT`, {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Báo cáo danh sách ăn ca_${dateRange.startDate}-${dateRange.endDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      alert('Có lỗi xảy ra khi xuất file Excel');
    }
  };
  // Hàm render để hiển thị luồng duyệt
  const renderApprovalFlow = () => {
    
    return (
      <ApprovalFlow
        dateRange={dateRange}
        approvalData={approvalData}
      />
    );
  };
    
    return (
    <div className="overtime-approval ">
      <NavTabs/>
      <h2 className="text-center my-3">Luồng duyệt danh sách ăn ca</h2>
      
      <div className="filter-controls">
        <div className="row g-3">
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Chọn khoảng thời gian:</label>
            <DateRangePicker
              initialSettings={{
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                maxDate: moment('DD/MM/YYYY'),
                locale: {
                  format: 'DD/MM/YYYY'
                }
              }}
              onApply={handleDateChange}
            >
              <input type="text" className="form-control" value={`${dateRange.startDate} - ${dateRange.endDate}`} readOnly />
            </DateRangePicker>
          </div>
        </div>
        <div className="row g-3 mt-3 action-buttons">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <button className="btn btn-success w-100" onClick={handleExportCSV}>
              Xuất file CSV import
            </button>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <button className="btn btn-success w-100" onClick={exportToExcelByDepartment}>
              Xuất file excel ký duyệt
            </button>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <button className="btn btn-success w-100" onClick={exportToExcel}>
              Xuất file excel tổng hợp
            </button>
          </div>
        </div>
      </div>
      <div className="table mt-3">     
        {renderApprovalFlow()}
      </div>
    </div>
  );
  }

  export default Workflowview;