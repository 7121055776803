import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../assets/style.css';
import { UserCircle, Key, LogOut, Bell } from 'lucide-react'; 
import { Modal, Button } from 'react-bootstrap';
import logo from '../assets/images/logocty.jpg';
import { API_URL } from '../authlogin/api';
const NavTabs = () => {
  const navigate = useNavigate();
  const [TenNV, setTenNV] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isAuthenticated = !!localStorage.getItem('token');
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [capDo, setcapDo] = useState(null);

  const MaNV = localStorage.getItem('MaNV');
  useEffect(() => {
  const storedTenNV = localStorage.getItem('TenNV');
  const storedcapDo = localStorage.getItem('capDo');
  if (storedTenNV) {
    setTenNV(storedTenNV);
  }
  if (storedcapDo) {
    setcapDo(parseInt(storedcapDo));
  }
  
}, [isAuthenticated]);
useEffect(() => {
  if (isAuthenticated) {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 60000); // Cập nhật mỗi phút
    return () => clearInterval(interval);
  }
}, [isAuthenticated]);
  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${API_URL}/notifications`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setNotifications(response.data.notifications);
      setNotificationCount(response.data.notifications.filter(n => !n.read).length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm('Bạn có muốn đăng xuất không?');
    if (confirmLogout) {
      localStorage.clear();
      setTenNV('');
      navigate('/login');
    }
  };

  const handleNotificationClick = () => {
    setShowNotifications(true);
   
  };

  const handleCloseNotifications = () => {
    setShowNotifications(false);
  };

  const handleNotificationItemClick = async (notificationId) => {
  try {
    await axios.post(`${API_URL}/mark-notification-read/${notificationId}`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    // Cập nhật state local
    setNotifications(notifications.map(n => 
      n._id === notificationId ? { ...n, read: true } : n
    ));
    setNotificationCount(prev => Math.max(0, prev - 1));
  } catch (error) {
    console.error('Error marking notification as read:', error);
  }
};

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light" style={{ display: document.title === 'Kiểm kê suất ăn' ? 'none' : 'block' }} id="neubar">
        <div className="container">
          <Link className="navbar-brand" to="/" style={{ display: 'flex', alignItems: 'center' }}>  
            <img src={logo} style={{marginRight:'10px'}} height="60" alt="Logo" />  
          <span className="navbar-brand mb-0 h1" style={{ color: '#5a454e' }}>Đăng ký ăn ca VLH</span>  
        </Link> 
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto mt-3 mt-lg-0">
              {isAuthenticated ? (
                <>
                  {capDo === 1 &&  MaNV === '00600' ? (
                    <>
                    <li className="nav-item">
                      <Link className={`nav-link mx-2 ${document.title === 'Xem luồng duyệt' ? 'active' : ''}`} to="/workflow">Xem luồng duyệt</Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Tổng hợp suất ăn' ? 'active' : ''}`} to="/tonghop">Tổng hợp</Link>
                      </li>
                      <li className="nav-item">
                      <Link className={`nav-link mx-2 ${document.title === 'Nhập dữ liệu' ? 'active' : ''}`} to="/nhapdulieu">Nhập dữ liệu</Link>
                    </li>
                      </>
                  ) :
                  capDo === 1&& MaNV !== '00055' && MaNV !== '00600' ? (
                    <li className="nav-item">
                      <Link className={`nav-link mx-2 ${document.title === 'Nhập dữ liệu' ? 'active' : ''}`} to="/nhapdulieu">Nhập dữ liệu</Link>
                    </li>
                    ) :
                      capDo === 1 && MaNV === '00055' ? (
                        <>
                    <li className="nav-item">
                      <Link className={`nav-link mx-2 ${document.title === 'Nhập dữ liệu' ? 'active' : ''}`} to="/nhapdulieu">Nhập dữ liệu</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={`nav-link mx-2 ${document.title === 'Xem luồng duyệt' ? 'active' : ''}`} to="/workflow">Xem luồng duyệt</Link>
                    </li>
                      </>
                    ) :
                      (
                    <>
                      <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Tổng quan' ? 'active' : ''}`} aria-current="page" to="/">Tổng quan</Link>
                      </li>
                      <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Duyệt suất ăn' ? 'active' : ''}`} aria-current="page" to="/duyetovertime">Duyệt ăn ca</Link>
                      </li>
                      <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Kiểm kê suất ăn' ? 'active' : ''}`} to="/scanner">Kiểm kê</Link>
                      </li>
                      <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Tổng hợp suất ăn' ? 'active' : ''}`} to="/tonghop">Tổng hợp</Link>
                      </li>
                    </>
                  )}
                  
                  <li className="nav-item dropdown">
                    <div className="nav-link" onClick={handleNotificationClick} style={{ cursor: 'pointer', position: 'relative' }}>
                      <Bell size={25} />
                      {notificationCount > 0 && (
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {notificationCount}
                          <span className="visually-hidden">unread notifications</span>
                        </span>
                      )}
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <div className="nav-link dropdown-toggle" style={{ cursor: 'pointer' }} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                      <UserCircle size={25} />
                      <span className="ms-2">{TenNV}</span>
                    </div>
                    {isMenuOpen && (
                      <ul className="dropdown-menu dropdown-menu-end show">
                        <li>
                          <Link className="dropdown-item" to="/changepassword">
                            <Key size={18} className="me-2" />
                            Đổi mật khẩu
                          </Link>
                        </li>
                        <li>
                          <button className="dropdown-item" onClick={handleLogout}>
                            <LogOut size={18} className="me-2" />
                            Đăng xuất
                          </button>
                        </li>
                      </ul>
                    )}
                  </li>
                </>
              ) : (
                <li className="nav-item">
                  <Link className="nav-link mx-2 active" to="/login">Đăng nhập</Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      <Modal show={showNotifications} onHide={handleCloseNotifications}>
      <Modal.Header closeButton>
        <Modal.Title>Thông báo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {notifications.length > 0 ? (
          <ul className="list-group">
            {notifications.map((notification) => (
              <li 
                key={notification._id} 
                className={`list-group-item ${notification.read ? 'text-muted' : 'fw-bold'}`}
                onClick={() => handleNotificationItemClick(notification._id)}
                style={{ cursor: 'pointer' }}
              >
                {notification.message}
                <small className="d-block text-muted">
                  {new Date(notification.createdAt).toLocaleString()}
                </small>
              </li>
            ))}
          </ul>
        ) : (
          <p>Không có thông báo mới.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseNotifications}>
          Đóng
        </Button>
      </Modal.Footer>
      </Modal>
    </>
  );
};

export default NavTabs;