// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAMtniSw6HkwPqzDGvxc4ko_Ezxl65alW8",
  authDomain: "pushnoficationsa.firebaseapp.com",
  projectId: "pushnoficationsa",
  storageBucket: "pushnoficationsa.appspot.com",
  messagingSenderId: "324299384161",
  appId: "1:324299384161:web:fd1b4d8b4f0c8f30ded120",
  measurementId: "G-CQ24WL34MK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };