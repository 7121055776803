import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import NavTabs from './NavTabs';
import "./Overview.css";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { Badge, Button, Modal } from 'react-bootstrap';
import { messaging } from './firebase';
import { getToken } from "firebase/messaging";
import { onMessage } from "firebase/messaging"; 
import { ToastContainer, toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import { API_URL } from '../authlogin/api';

function Overview() {
  document.title = "Tổng quan";
  const [activeItem, setActiveItem] = useState('pending');
  const [groupedLogs, setGroupedLogs] = useState({});
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(10, 'days').format('DD/MM/YYYY'),
    endDate: moment().add(10, 'days').format('DD/MM/YYYY')
  });
  const [userInfo, setUserInfo] = useState(null);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [selectedLog, setSelectedLog] = useState(null);
  
  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      fetchLogs();
    }
  }, [activeItem, dateRange, userInfo]);

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      toast.info(payload.notification.body);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const getPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return 'android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'ios';
    }
    return 'web';
  };
  
  const vapidKey = 'BF8gF-tiOKXY6oAKz8qjCpjCudtnPnz75ZQSbUbcPQxUc2Y8flAklj1OE1SIir5hIS3fuhYpxkG7eu8cBWpth6I';

  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const token = await getToken(messaging, { vapidKey });
        await sendTokenToServer(token);
      } else {
        console.log('Notification permission denied');
      }
    } catch (error) {
      console.error('Error requesting notification permission:', error);
    }
  };
  
  const sendTokenToServer = async (token) => {
    try {
      const platform = getPlatform();
      await axios.post(`${API_URL}/register-device`, { token, platform }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
    } catch (error) {
      console.error('Error sending token to server:', error);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(`${API_URL}/user-info`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUserInfo(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const fetchLogs = async () => {
    try {
      const response = await axios.get(`${API_URL}/logs`, {
        params: {
          status: activeItem,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const sortedLogs = Object.values(response.data).sort((a, b) => {
        return moment(b.date, 'DD/MM/YYYY').valueOf() - moment(a.date, 'DD/MM/YYYY').valueOf();
      });
      setGroupedLogs(sortedLogs);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const handleDateChange = (event, picker) => {
    setDateRange({
      startDate: picker.startDate.format('DD/MM/YYYY'),
      endDate: picker.endDate.format('DD/MM/YYYY')
    });
  };

  const handleReject = (log) => {
    setSelectedLog(log);
    setShowRejectModal(true);
  };

  const handleRejectConfirm = async () => {
    try {
      await axios.post(`${API_URL}/reject-overtime`, {
        logId: selectedLog.id,
        reason: rejectReason,
        startDate: selectedLog.startDate,
        endDate: selectedLog.endDate
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
  
      // Cập nhật trạng thái của tất cả các log trong khoảng thời gian
      setGroupedLogs(prevLogs => {
        return prevLogs.map(log => {
          if (moment(log.startDate, 'DD/MM/YYYY').isSameOrAfter(moment(selectedLog.startDate, 'DD/MM/YYYY')) &&
              moment(log.endDate, 'DD/MM/YYYY').isSameOrBefore(moment(selectedLog.endDate, 'DD/MM/YYYY'))) {
            return {
              ...log,
              tuchoi: true,
              daduyet: false
            };
          }
          return log;
        });
      });
  
      toast.success('Đã từ chối yêu cầu ăn ca');
      setShowRejectModal(false);
      setRejectReason('');
      await fetchLogs();
    } catch (error) {
      console.error('Error rejecting overtime:', error);
      toast.error('Có lỗi xảy ra khi từ chối yêu cầu ăn ca');
    }
  };

  const getStatus = (group) => {
    if (group.tuchoi) return "rejected";
    if (group.daduyet) return "approved";
    return "pending";
  };
  
  const getStatusBadge = (status) => {
    switch (status) {
      case "rejected":
        return <Badge bg="danger">Đã từ chối <FaTimes /></Badge>;
      case "approved":
        return <Badge bg="success">Đã duyệt</Badge>;
      case "pending":
        return <Badge bg="warning">Chờ duyệt</Badge>;
      default:
        return null;
    }
  };

  return (
    <>
      <NavTabs />
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-12 ms-sm-auto px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Tổng quan ăn ca</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                  <DateRangePicker
                    initialSettings={{
                      startDate: dateRange.startDate,
                      endDate: dateRange.endDate,
                      maxDate: moment('DD/MM/YYYY'),
                      locale: {
                        format: 'DD/MM/YYYY'
                      }
                    }}
                    onApply={handleDateChange}
                  >
                    <button type="button" className="btn btn-sm btn-outline-secondary">
                      {dateRange.startDate} - {dateRange.endDate}
                    </button>
                  </DateRangePicker>
                </div>
              </div>
            </div>

            <div className="filter-container">
              <h4>Lọc theo trạng thái</h4>
              <div className="filter-options">
                <label className={`filter-option ${activeItem === 'all' ? 'active' : ''}`}>
                  <input
                    type="radio"
                    name="filter"
                    value="all"
                    checked={activeItem === 'all'}
                    onChange={() => handleItemClick('all')}
                  />
                  <span>Tất cả</span>
                </label>
                <label className={`filter-option ${activeItem === 'approved' ? 'active' : ''}`}>
                  <input
                    type="radio"
                    name="filter"
                    value="approved"
                    checked={activeItem === 'approved'}
                    onChange={() => handleItemClick('approved')}
                  />
                  <span>Đã duyệt</span>
                </label>
                <label className={`filter-option ${activeItem === 'pending' ? 'active' : ''}`}>
                  <input
                    type="radio"
                    name="filter"
                    value="pending"
                    checked={activeItem === 'pending'}
                    onChange={() => handleItemClick('pending')}
                  />
                  <span>Chờ duyệt</span>
                </label>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-striped table-sm">
                <thead>
                  <tr>
                    <th>Ngày ăn ca</th>
                    <th>Tên đề xuất</th>
                    <th>Phòng ban</th>
                    <th>Người đề xuất</th>
                    <th>Tổng số lượng</th>
                    <th>Lý do</th>
                    <th>Trạng thái</th>
                    <th>Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(groupedLogs).map((group, groupIndex) => {
                    const status = getStatus(group);
                    return (
                      <tr 
                        key={groupIndex}
                        className={status === "rejected" ? 'rejected-row' : ''}
                      >
                        <td>{`${group.startDate} - ${group.endDate}`}</td>
                        <td>
                          <Link to={`/duyetovertime?startDate=${group.startDate}&endDate=${group.endDate}&phongBan=${encodeURIComponent(group.department)}&showApprovalFlow=true`}>
                            {group.tendexuat}
                          </Link>
                        </td>
                        <td>{group.department}</td>
                        <td>{group.nguoidexuat}</td>
                        <td>{group.soluong}</td>
                        <td>{group.lydo}</td>
                        <td>{getStatusBadge(status)}</td>
                        <td>
                          {status === "pending" && (
                            <Button variant="danger" size="sm" onClick={() => handleReject(group)}>
                              Từ chối
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Từ chối yêu cầu ăn ca</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="form-group">
                    <label>Lý do từ chối:</label>
                    <textarea
                      className="form-control"
                      rows="3"
                      value={rejectReason}
                      onChange={(e) => setRejectReason(e.target.value)}
                    ></textarea>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowRejectModal(false)}>
                  Hủy
                </Button>
                <Button variant="primary" onClick={handleRejectConfirm}>
                  Xác nhận từ chối
                </Button>
              </Modal.Footer>
            </Modal>
          </main>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Overview;