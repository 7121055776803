import React from 'react';
import { Link } from 'react-router-dom';
import './Unauthorized.css'; // Đảm bảo tạo file CSS này

const Unauthorized = () => {
  return (
    <div className="unauthorized-container">
      <div className="unauthorized-content">
        <h1>403</h1>
        <h2>Không có quyền truy cập</h2>
        <p>Xin lỗi, bạn không có quyền truy cập trang này.</p>
        <Link to="/" className="home-button">Quay lại trang chủ</Link>
      </div>
    </div>
  );
};

export default Unauthorized;