import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import NavTabs from '../component/NavTabs';
import '../assets/style.css';
import { API_URL } from './api';
const Login = () => {
  const [form, setForm] = useState({ MaNV: '', MatKhau: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  
  document.title = 'Đăng nhập';
  
  // Lấy route mục tiêu từ query parameter
  const targetRoute = new URLSearchParams(location.search).get('redirect') ;

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  }; 

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${API_URL}/login`, form);
      // console.log(res.data);
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('capDo', res.data.capDo);
      localStorage.setItem('capCuoi', res.data.capCuoi);
      localStorage.setItem('TenNV', res.data.TenNV);
      localStorage.setItem('MaNV', res.data.MaNV);
      localStorage.setItem('isShowData', res.data.isShowData);

      // Chuyển hướng đến route mục tiêu sau khi đăng nhập thành công
      navigate(targetRoute || res.data.defaultRoute);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setError('Sai tài khoản hoặc mật khẩu. Vui lòng thử lại.'); 
      } else {
        setError('Đăng nhập thất bại. Vui lòng thử lại sau.');
        console.error('Lỗi đăng nhập:', err);
      }
    }
  };

  return (
    <div>
      <NavTabs/>
      <div className="container bg-web mt-5 pt-3 mw-400">
        <form onSubmit={handleSubmit}>
          <div className="mb-4 row">
            <h1 className="text-center">Đăng nhập</h1>
          </div>
          <div className="mb-4">
            <label htmlFor="MaNV" className="form-label">Tài khoản</label>
            <input
              type="text"
              className="form-control"
              id="MaNV"
              name="MaNV"
              placeholder="Nhập số thẻ nhân viên có 5 chữ số"
              required
              value={form.MaNV}
              onChange={handleChange}
            />
            <div className="invalid-feedback">
              Nhập tên đăng nhập viết liền không dấu
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="MatKhau" className="form-label">Mật khẩu</label>
            <input
              type="password"
              className="form-control"
              id="MatKhau"
              name="MatKhau"
              placeholder="******"
              required
              value={form.MatKhau}
              onChange={handleChange}
            />
            <div className="invalid-feedback">
              Nhập mật khẩu
            </div>
          </div>
          {error && <div className="alert alert-danger" style={{ fontSize: 'small' }}>{error}</div>}
          <div className="row">
            <button type="submit" className="col-md-6 col-lg-4 col-12 btn btn-success height-fill-available mb-4 font-size-1-2 mx-auto">Đăng nhập</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;