import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import SummaryPage from './component/Tonghop.jsx';
import Scanner from './component/QRsanner.jsx';
import Suatan from './component/client.jsx';
import LoginPage from './authlogin/loginpage.jsx';
import './assets/style.css';
import Unauthorized from './authlogin/Unauthorized.js';
import DataEntryPage from './component/nhapdulieu.jsx';
import OvertimeApproval from './component/overtimeapproval.jsx';
import ChangePassword from './authlogin/changepassword.jsx';
import WorkflowView from './component/workfowview.jsx';
import Overview from './component/overview.jsx';

const RootRedirect = () => {
  const capDo = parseInt(localStorage.getItem('capDo'));
  
  if (capDo === 1) {
    return <Navigate to="/nhapdulieu" replace />;
  } else {
    return <Overview />;
  }
};
const ProtectedRoute = ({ allowedCapDo, requireCapCuoi, excludedUsers = [] }) => {
  const location = useLocation();
  const token = localStorage.getItem('token');
  const capDo = parseInt(localStorage.getItem('capDo'));
  const capCuoi = localStorage.getItem('capCuoi') === 'true';
  const TenNV = localStorage.getItem('TenNV');
  const MaNV = localStorage.getItem('MaNV');
  if (!token) {
    // Redirect to login with the current path and query parameters
    return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`} replace />;
  }
   if (excludedUsers.includes(TenNV)) {
    return <Navigate to="/unauthorized" replace />;
  }
  if (allowedCapDo.includes(capDo) && (!requireCapCuoi || capCuoi)|| MaNV==='00055' || MaNV==='00600') {
    return <Outlet />;
  } else {
    return <Navigate to="/unauthorized" replace />;
  }
};

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path='/changepassword' element={<ChangePassword/>}/>
          
          {/* Route cho tất cả người dùng đã đăng nhập */}
          <Route element={<ProtectedRoute allowedCapDo={[1,2,3,4,5,6,10]} requireCapCuoi={false} />}>
            <Route path='/' element={<RootRedirect/>}/>
          </Route>

          <Route element={<ProtectedRoute allowedCapDo={[4,5,6,10]} requireCapCuoi={false} />}>
            <Route path="/scanner" element={<Scanner />} />
          </Route>

          {/* Route cho nhập dữ liệu */}
          <Route element={<ProtectedRoute allowedCapDo={[1,2]} requireCapCuoi={false} />}>
            <Route path="/nhapdulieu" element={<DataEntryPage />} />
          </Route>

          {/* Route cho duyệt overtime */}
          <Route element={<ProtectedRoute allowedCapDo={[1,2,3,4,5,6]} requireCapCuoi={false} />}>
            <Route path="/duyetovertime" element={<OvertimeApproval />} />
          </Route>

          {/* Routes cho cấp cao nhất */}
          <Route element={<ProtectedRoute allowedCapDo={[3,4,5,6,99,10]} requireCapCuoi={false} />}>
            <Route path="/scanner" element={<Suatan />} />
            <Route path="/tonghop" element={<SummaryPage />} />
          </Route>

          <Route element={<ProtectedRoute allowedCapDo={[0,5,3]} requireCapCuoi={false} MaNV={'00055' || '00600'} />}>
            <Route path='/workflow' element={<WorkflowView/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;