import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../assets/style.css';
import NavTabs from '../component/NavTabs';
import { API_URL } from './api';
const ChangePassword = () => {
  const [form, setForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (form.newPassword !== form.confirmNewPassword) {
      setError('Mật khẩu mới và xác nhận mật khẩu không khớp');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}/change-password`,
        {
          currentPassword: form.currentPassword,
          newPassword: form.newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSuccess('Đổi mật khẩu thành công');
      setTimeout(() => {
        navigate('/'); // Chuyển hướng về trang chủ sau khi đổi mật khẩu thành công
      }, 2000);
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.error);
      } else {
        setError('Đã xảy ra lỗi khi đổi mật khẩu');
      }
    }
  };

    return (
        <>
            <NavTabs/>
            
        <div className="container bg-web mt-5 pt-3 mw-420">
          
        <div className="mb-4 row">
      <h1 className="text-center">Đổi mật khẩu</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="currentPassword" className="form-label" style={{fontWeight:'500'}}>Mật khẩu hiện tại</label>
          <input
            type="password"
            className="pass-change form-control"
            id="currentPassword"
            name="currentPassword"
            placeholder='nhập mật khẩu hiện tại'
            value={form.currentPassword}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="newPassword" className="form-label"style={{fontWeight:'500'}}>Mật khẩu mới</label>
          <input
            type="password"
            className="pass-change form-control"
            id="newPassword"
            name="newPassword"
            placeholder='nhập mật khẩu mới'
            value={form.newPassword}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="confirmNewPassword" className="form-label"style={{fontWeight:'600'}}>Xác nhận mật khẩu mới</label>
          <input
            type="password"
            className="pass-change form-control"
            id="confirmNewPassword"
            name="confirmNewPassword"
            placeholder='xác nhận lại mật khẩu mới'
            value={form.confirmNewPassword}
            onChange={handleChange}
            required
          />
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        {success && <div className="alert alert-success">{success}</div>}
        <div className='text-center'>
                    
            <button type="submit" className="col-md-6 col-lg-4 col-12 btn btn-primary height-fill-available mb-4 font-size-1-2 mx-auto text-center">Xác nhận</button>
        </div>
          </form>
          </div>
            </div>
            </>
  );
};

export default ChangePassword;