import React, { useState, useEffect,useMemo } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';
import './OvertimeApproval.css';

const ApprovalFlow = ({ dateRange, approvalData, deptId, availableDepartments }) => {
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const start = moment(dateRange.startDate, 'DD/MM/YYYY');
    const end = moment(dateRange.endDate, 'DD/MM/YYYY');
    const datesTabs = [];

    for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
      datesTabs.push(m.format('DD/MM/YYYY'));
    }

    setTabs(datesTabs);
  }, [dateRange]);
  
//   const filteredApprovalData = useMemo(() => {
//     if (!approvalData) return {};
    
//     return Object.fromEntries(
//       Object.entries(approvalData).map(([date, dateData]) => [
//         date,
//         {
//           ...dateData,
//           approvalFlows: Object.fromEntries(
//             Object.entries(dateData.approvalFlows).filter(([dept]) => 
//               deptId === '' || deptId === 'Tất cả' 
//                 ? availableDepartments.includes(dept)
//                 : dept === deptId
//             )
//           )
//         }
//       ])
//     );
//   }, [approvalData, deptId, availableDepartments]);
  const calculateTotalApprovedAndRegistered = (date) => {
    const dateData = approvalData[date];
    if (!dateData) return { totalApproved: 0, totalRegistered: 0 };

    let totalApproved = 0;
    let totalRegistered = 0;

    Object.entries(dateData.approvalFlows).forEach(([deptName, flow]) => {
      // Tìm cấp độ phê duyệt cuối cùng
      const finalLevel = flow.find(step => step.isFinal)?.level;
      
      // Chỉ tính số lượng phê duyệt từ cấp độ cuối cùng
      if (finalLevel) {
        totalApproved += dateData.approvalLogs[deptName]?.[finalLevel] || 0;
      }
      // Tổng hợp tất cả số lượng đăng ký cho mỗi phòng ban
      totalRegistered += dateData.reasonsAndQuantities[deptName]?.count || 0;
    });
    
    return { totalApproved, totalRegistered };
  };

  const renderApprovalFlow = (date) => {
    const dateData = approvalData[date];
    if (!dateData) return null;

    return (
      <div className="approval-flows-container">
        {Object.entries(dateData.approvalFlows).map(([deptName, flow], index) => {
          // Find the final approval level
          const finalLevel = flow.find(step => step.isFinal)?.level;
          // Get the final approved count
          const finalApprovedCount = dateData.approvalLogs[deptName]?.[finalLevel] || 0;
          
          return (
            <div key={deptName} className="approval-flow-container">
              <div className="approval-flow-header">
                <div className="approval-flow-column">  
                  <h4>Đề xuất của: {deptName.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</h4>  
                </div>  
                <div className="approval-flow-column1">
                  {dateData.reasonsAndQuantities[deptName]?.reasons?.map((reason, idx) => (
                    <div key={idx} className="reason-item">
                      <span className="reason" style={{fontWeight:"600",fontSize:"20px"}}>
                        Lý do: {reason.reason}-{reason.mahang}-{reason.khachhang}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="approval-flow-column2">
                  {dateData.reasonsAndQuantities[deptName]?.reasons?.map((reason, idx) => (
                    <div key={idx} className="duration-item">
                      <span className="duration" title="Thời gian ăn ca" style={{fontWeight:"600",fontSize:"20px"}} >
                        Thời gian: {reason.workingHours} giờ
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="approval-flow mt-4 mb-4">
              {flow.map((step, index) => {
                const isCompleted = index < dateData.currentSteps[deptName];
                const isCurrent = index === dateData.currentSteps[deptName];
                const isFinal = step.isFinal;
                const approvedCount = dateData.approvalLogs[deptName]?.[step.level] || 0;
                const totalRegistered = dateData.reasonsAndQuantities[deptName]?.count || 0;
                
                // Tính toán số lượng chờ duyệt
                let pendingCount = 0;
                if (index === 0) {
                  // Ở cấp đầu tiên, số chờ duyệt là tổng số đăng ký trừ số đã duyệt
                  pendingCount = totalRegistered - approvedCount;
                } else {
                  // Ở các cấp tiếp theo, số chờ duyệt là số đã duyệt ở cấp trước trừ số đã duyệt ở cấp hiện tại
                  const previousApprovedCount = dateData.approvalLogs[deptName]?.[flow[index - 1].level] || 0;
                  pendingCount = previousApprovedCount - approvedCount;
                }

                // Chỉ hiển thị số chờ duyệt nếu đã đến lượt cấp này hoặc các cấp trước
                const showPendingCount = index <= dateData.currentSteps[deptName];

                return (
                  <div
                    key={index}
                    className={`approval-step ${isCompleted ? 'completed' : ''} ${isCurrent ? 'current' : ''}`}
                  >
                    <div className="step-number">{step.level}</div>
                    <div className="approver-name">{step.approverName}</div>
                    <div className="approved-count">Đã duyệt: {approvedCount}</div>
                    {showPendingCount && <div className="pending-count">Chờ duyệt: {pendingCount}</div>}
                    {isFinal && <div className="final-step">{isCompleted ? 'Đã duyệt' : 'Đang đợi duyệt'}</div>}
                  </div>
                    
                  );
                  
                })}
                
              </div>
              {index < Object.entries(dateData.approvalFlows).length - 1 && <hr className="approval-flow-separator" />}
            </div>
          );
        })}
      </div> 
    );
  };

  return (
    <Tabs className="nav-tabs-container"> 
      <TabList className="nav nav-tabs">
        {tabs.map((date) => {
          const { totalApproved, totalRegistered } = calculateTotalApprovedAndRegistered(date);
          return (
            <Tab key={date} className="nav-item">
              <span className="nav-link">
                {date}
                <br />
                <small style={{color:"#b52323"}}>Đăng ký: {totalRegistered}/ Tổng đã duyệt:{totalApproved}</small>
                {/* / Tổng đã duyệt:{totalApproved} */}
              </span>
            </Tab>
          );
        })}
      </TabList>

      {tabs.map((date) => (
        <TabPanel key={date} className="tab-content">
          <div className="tab-pane fade show active">
            {renderApprovalFlow(date)}
          </div>
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default ApprovalFlow;